<template>
	<b-nav-item-dropdown
		variant="link"
		menu-class="animate slideIn"
		ref="dropdown"
		class="d-lg-none"
	>
		<template v-slot:button-content>
			<span
				class="nav-link--icon"
			>
				<font-awesome-icon
					:icon="[
						'fal',
						'bars'
					]"
				/>
				Mehr
			</span>
		</template>
			<ul class="mobile-menu">
				<div class="mobile-menu--header">
					<span class="icon-block">
						<font-awesome-icon
							:icon="[
								'fal',
								'bars'
							]"
						/>
						Mehr
					</span>
					<button
						@click="closeDropdown()"
						aria-label="Navigation Schließen"
						class="btn btn-link"
					>
						<font-awesome-icon
							:icon="[
								'fal',
								'times'
							]"
						/>
					</button>
				</div>
				<b-dropdown-item>
					<li
						v-for="(metaMenuItem, metaMenuIndex) in navMain"
						:key="metaMenuIndex"
						class="level-1--content level-2--list"
					>
					<nav-link :to="metaMenuItem.link">
						<span
							class="nav-link--icon"
							v-if="isIconPresent(metaMenuItem)"
						>
							<font-awesome-icon
								:icon="[
									metaMenuItem.icon.prefix,
									metaMenuItem.icon.iconName
								]"
							/>
						</span>
						{{ metaMenuItem.title }}
						</nav-link>
					</li>
				</b-dropdown-item>
				<!--li class="dropdown--list-search">
					<div class="input-group">
						<input
							type="text"
							class="form-control"
							placeholder="Benutzername"
							aria-label="Benutzername"
							aria-describedby="Login"
						>
							<a
								href="https://mitarbeiterportal.isd-service.de/fuer-mitarbeiter/mitarbeiterportal/"
								target="_blank"
								rel="noopener"
							>
								<font-awesome-icon
									:icon="[
										'isd',
										'login'
									]"
								/>
								Login
							</a>
					</div>
				</li>
				<li class="dropdown--list-login">
					<div class="input-group">
						<input
							type="text"
							class="form-control"
							placeholder="Ihr Suchbegriff…"
							aria-label="Suche"
							aria-describedby="Suche"
						>
						<a href="#">
							<font-awesome-icon
								:icon="[
									'isd',
									'magnifier'
								]"
							/>
							<span class="dropdown--list-login-text">
								Suche
							</span>
						</a>
					</div>
				</li> -->
			</ul>
	</b-nav-item-dropdown>
</template>

<script>
import { mapState } from 'vuex';
import { isIconPresentInObject } from '~/plugins/utilities/ObjectUtility';
export default {
	name: 'NavigationMainMobile',
	props: {
		links: {
			type: Array,
			default: () => []
		},
		metaMenu: {
			type: Array
		}
	},
	methods: {
		isIconPresent (object) {
			return isIconPresentInObject(object);
		},
		closeDropdown () {
			this.$refs.dropdown.hide();
		}
	},
	computed: {
		...mapState({
			navMain: (state) => {
				return state.typo3?.initial?.navigation?.[0].metaMenu || [];
			},
		})
	}
}
</script>

<style lang="scss">
	.mobile-menu--header {
		.btn-link {
			padding: 0;
			width: 30px;
			height: 30px;
		}
	}
</style>
