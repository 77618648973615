<template>
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li
				v-for="(level, key) in links"
				:key="key"
				class="breadcrumb-item"
			>
				<template>
					<component
						:is="level.current ? 'span' : 'nav-link'"
						:to="!level.current ? level.link : false"
					>
						{{ level.title }}
					</component>
				</template>
			</li>
		</ol>
	</nav>
</template>

<script>
export default {
	name: 'Breadcrumbs',
	props: {
		/* array of breadcrumbs links */
		links: {
			type: Array,
			required: true,
			default: () => []
		}
	}
}
</script>

<style lang="scss">
	@import '~@/assets/scss/common';
	$breadcrumb--margin-top: 5px !default;

	.breadcrumb {
		display: flex;
		justify-content: center;
		margin-top: $breadcrumb--margin-top;
		@include responsive-type(-1, 1.5);
	}

</style>
