<template>
		<div class="ce-uploads--container">
			<ce-header v-bind="$props" />
			<ul
				v-if="media"
				class="ce-uploads row"
			>
				<li
					v-for="(file, key) in media"
					:key="key"
					class="col"
				>
					<div
						itemscope
						itemtype="https://schema.org/MediaObject"
					>
					<client-only>
						<nav-link
							:to="file.publicUrl"
							:target="target"
							class="ce-uploads-link"
						>
							<div class="ce-uploads-thumbnail-wrapper">
								<span
									v-if="displayInformation == 1"
									class="ce-uploads--icon-extension"
								>
									<i
									:class="[
										`ce-uploads-icon icon-file-${file.properties.extension}`
										]">
									</i>
								</span>
								<span
									v-if="displayInformation == 2 && file.properties.type === 'image'"
									class="ce-uploads--thumb"
								>
									<ce-media-file :file="file" />
								</span>
							</div>
							<div class="ce-uploads-details-wrapper">
								<div
									class="ce-uploads-description"
									itemprop="description"
								>
									{{ file.properties.title || file.publicUrl }}
								</div>
								<div class="ce-uploads-additional-details">

									<div
										v-if="displayFileSizeInformation"
										class="ce-uploads-filesize"
										itemprop="contentSize"
									>
										{{ file.properties.size }}
									</div>

									<div
										v-if="displayDescription && file.properties.description"
										class="ce-uploads-modification-date"
									>
										{{ file.properties.description }}
									</div>

								</div>
							</div>
						</nav-link>
					</client-only>
					</div>
				</li>
			</ul>
		</div>
</template>

<script>
	import CeUploads from '~typo3/components/content/elements/CeUploads.vue';
	export default {
		extends: CeUploads,
		props: {
			displayInformation: {
				type: String,
				required: true
			},
			displayFileSizeInformation: {
				type: String,
				required: true
			},
			displayDescription: {
				type: String,
				required: true
			}
		}
	}
	</script>

<style lang="scss">
	@import '~@/assets/scss/common';
	$ce-uploads-thumbnail-wrapper-border-color: color(primary, base) !default;
	$ce-uploads-hover-transition:  all 0.3s !default;
	$ce-uploads-icon-color: color(white) !default;
	$ce-uploads-icon--background-color: color(primary, light) !default;
	$ce-uploads-icon--background-color-hover: color(secondary, base) !default;
	$ce-uploads-icon-width: rem(100) !default;
	$ce-uploads-details-wrapper-margin: 10px !default;
	$ce-uploads-description-color: color(secondary, base) !default;
	$ce-uploads-description-color-hover: color(primary, base) !default;
	$ce-uploads-additional-details-color: color(grey, dark) !default;

	$ce-uploads-icon-pdf: file-pdf !default;
	$ce-uploads-icon-jpg: file-image !default;
	$ce-uploads-icon-video: file-video !default;

	.ce-uploads {
		padding: 0;
		list-style: none;
	}

	.ce-uploads-link {
		display: block;
		text-decoration: none;


		&:hover,
		&:focus {
			text-decoration: none;

			.ce-uploads-description {
				color: $ce-uploads-description-color;
			}
			.ce-uploads--icon-extension {
				background: $ce-uploads-icon--background-color-hover;
				transform: scale(1.1);
			}
			img {
				transform: scale(1.06);
			}

		}

		img {
			transition: $ce-uploads-hover-transition;
			transform: scale(1);
		}

			figure {
				position: relative;
				overflow: hidden;
				width: max-content;

				&::before {
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					border: 2px solid $ce-uploads-thumbnail-wrapper-border-color;
					z-index: 1;
			}
		}

	}

	.ce-uploads-thumbnail-wrapper {
		flex: 0 0 auto;

		img {
			outline: 1px solid $ce-uploads-thumbnail-wrapper-border-color;
		}
	}

	.ce-uploads--thumb {
		figcaption {
			display: none;
		}
	}


	.ce-uploads--icon-extension {
		display: flex;
		margin-top: unit(4);
		color: $ce-uploads-icon-color;
		@include responsive-type(5, 1.0);
		background: $ce-uploads-icon--background-color;
		width: $ce-uploads-icon-width;
		height: $ce-uploads-icon-width;
		padding: rem(10);
		border-radius: 100%;
		align-items: center;
		align-content: center;
		justify-content: center;
		text-align: center;
		transition: all 0.3s;

		svg {
			margin: 0 auto;
		}

	}

	.icon-file-pdf {
		&:before {
			font-family: "Font Awesome 5 Pro";
			content: fa-content($fa-var-file-pdf);
			@extend %fa-icon;
		}
	}

	.icon-file-jpg {
		&:before {
			font-family: "Font Awesome 5 Pro";
			content: fa-content($fa-var-file-image);
			@extend %fa-icon;
		}
	}

	.icon-file-video {
		&:before {
			font-family: "Font Awesome 5 Pro";
			content: fa-content($fa-var-video);
			@extend %fa-icon;
		}
	}

	.ce-uploads-details-wrapper {
		overflow: hidden;
		margin-top: $ce-uploads-details-wrapper-margin;
	}

	.ce-uploads-description {
		color: $ce-uploads-description-color;
		font-weight: 700;
	}

	.ce-uploads-additional-details {
		margin-top: 5px;
		overflow: hidden;
		font-size: 0.8em;
		text-decoration: none;
		color: $ce-uploads-additional-details-color;

		& > div {
			display: inline-block;

			&:before {
				content: " | ";
			}
		}
	}

</style>
