var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-nav-item-dropdown',{ref:"dropdown",staticClass:"d-lg-none",attrs:{"variant":"link","menu-class":"animate slideIn"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"nav-link--icon"},[_c('font-awesome-icon',{attrs:{"icon":[
					'fal',
					'bars'
				]}}),_vm._v("\n\t\t\tMehr\n\t\t")],1)]},proxy:true}])},[_vm._v(" "),_c('ul',{staticClass:"mobile-menu"},[_c('div',{staticClass:"mobile-menu--header"},[_c('span',{staticClass:"icon-block"},[_c('font-awesome-icon',{attrs:{"icon":[
							'fal',
							'bars'
						]}}),_vm._v("\n\t\t\t\t\tMehr\n\t\t\t\t")],1),_vm._v(" "),_c('button',{staticClass:"btn btn-link",attrs:{"aria-label":"Navigation Schließen"},on:{"click":function($event){return _vm.closeDropdown()}}},[_c('font-awesome-icon',{attrs:{"icon":[
							'fal',
							'times'
						]}})],1)]),_vm._v(" "),_c('b-dropdown-item',_vm._l((_vm.navMain),function(metaMenuItem,metaMenuIndex){return _c('li',{key:metaMenuIndex,staticClass:"level-1--content level-2--list"},[_c('nav-link',{attrs:{"to":metaMenuItem.link}},[(_vm.isIconPresent(metaMenuItem))?_c('span',{staticClass:"nav-link--icon"},[_c('font-awesome-icon',{attrs:{"icon":[
								metaMenuItem.icon.prefix,
								metaMenuItem.icon.iconName
							]}})],1):_vm._e(),_vm._v("\n\t\t\t\t\t"+_vm._s(metaMenuItem.title)+"\n\t\t\t\t\t")])],1)}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }