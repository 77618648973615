<template>
	<ul class="row teaser-menu-list">
		<li
			v-for="(menuItem, key) in menu"
			:key="key"
			class="teaser-menu-item col-md-6"
		>
			<nav-link
				:to="menuItem.link"
				:target="menuItem.target || false"
				:title="menuItem.title"
			>
				<div class="teaser-menu-item-image">
					<nuxt-img
						v-if="getNavImage(key)"
						:src="getNavImageSrc(key)"
						:alt="getNavImageProperties(key).alternative"
						format="webp"
						:sizes="{ sm: '30vw', md: '50vw', lg: '50vw', xxl: '500px' }"
						preload
					/>
				</div>

				<div class="teaser-menu-item-icon">
					<font-awesome-icon
						:icon="[
							menuItem.icon.prefix,
							menuItem.icon.iconName
						]"
					/>
				</div>

				<div class="teaser-menu-item-title">
					{{ menuItem.title }}
				</div>
			</nav-link>
		</li>
	</ul>
</template>

<script>
	export default {
		name: 'TeaserMenuItem',
		props: {
			menu: {
				type: Array,
				required: true,
				default: () => []
			}
		},
		methods: {
			getNavImage (index) {
				return this.menu[index]?.navImage
					|| [/* empty array */]
				;
			},
			getNavImageSrc (index) {
				const navImage = this.getNavImage(index);
				return navImage[0]?.publicUrl
					|| navImage[0]?.publicUrl
					|| null
				;
			},
			getNavImageProperties (index) {
				const navImage = this.getNavImage(index);
				return navImage[0]?.properties
					|| {/* empty object */}
				;
			}
		}
	}
</script>

<style lang="scss">
	@import '~@/assets/scss/common';
	$teaser-menu-item-title-padding: rem(30) !default;
	$teaser-menu-item-border: $default--border-color !default;
	$teaser-menu-item-title-transition: .3s ease-in-out !default;
	$teaser-menu-item-icon-color: color(white) !default;
	$teaser-menu-item-icon-background-color: rgba(93, 186, 229, 0.91) !default;
	$teaser-menu-item-icon-background-color-hover: color(secondary, base) !default;
	$teaser-menu-item-icon-background-transition: background-color 0.5s ease !default;
	$teaser-menu-item-icon-margin: -80px !default;
	$teaser-menu-item-icon-width: rem(100) !default;
	$teaser-menu-item-icon-width-lg: 70px !default;

	.teaser-menu-list {

		.teaser-menu-item {
			position: relative;

			&:hover {
				.teaser-menu-item-icon {
					background: $teaser-menu-item-icon-background-color-hover;
				}
					img {
						transform: scale(1.05);
					}
			}
		}

		.teaser-menu-item-image {
			position: relative;
			overflow: hidden;
			border-top-left-radius: 30px;
			border-top-right-radius: 30px;

			img {
				display: block;
				width: 100%;
				height: 100%;
				margin: 0 auto;

				object-fit: cover;
				font-family: 'object-fit: cover;';
				transition: $teaser-menu-item-title-transition;
				transform: scale(1);
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}

		}

		.teaser-menu-item-title {
			padding: $teaser-menu-item-title-padding;
			background: $teaser-menu-item-title-padding;
			border: 1px solid $teaser-menu-item-border;
			margin-bottom: $teaser-menu-item-title-padding;
			border-bottom-right-radius: 30px;
			border-bottom-left-radius: 30px;
		}

		.teaser-menu-item-icon {
			position: absolute;
			margin-top: $teaser-menu-item-icon-margin;
			right: 7%;
			display: grid;
			text-align: center;
			@include responsive-type(4, 1.0);
			transition: $teaser-menu-item-icon-background-transition;

			width: $teaser-menu-item-icon-width;
			height: $teaser-menu-item-icon-width;
			background: $teaser-menu-item-icon-background-color;
			color: $teaser-menu-item-icon-color;
			padding: rem(8);
			border-radius: 50%;
			margin-right: 0;

			@include media-breakpoint-down(xl) {
				width: $teaser-menu-item-icon-width-lg;
				height: $teaser-menu-item-icon-width-lg;
				margin-top: -50px;
			}

			@include media-breakpoint-between(md,lg) {
				margin-right: 10px;
			}

			svg {
				margin: 0 auto;
			}

		}

		.teaser-menu-item-icon > * {
			align-self: center
		}

	}
</style>
