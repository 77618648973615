<template>
	<svg
			xmlns="http://www.w3.org/2000/svg"
			class="mouse"
			viewBox="0 0 100 100"
		>
			<path
				d="M55.72 74.51L50 80.23l-5.72-5.72a1.25 1.25 0 00-1.77 1.77l6.61 6.6a1.23 1.23 0 001.76 0l6.61-6.6a1.25 1.25 0 00-1.77-1.77z"
				class="wheel"
			></path>

			<path d="M50.13 14.32h-.25a19.06 19.06 0 00-19 19V52.65a19 19 0 0019 19h.25a19.06 19.06 0 0019-19v-19.3a19.05 19.05 0 00-19-19.03zm-.26 2.5h.25a16.55 16.55 0 0116.53 16.53v1.71h-15.4V22.34A1.25 1.25 0 0050 21.09a1.25 1.25 0 00-1.25 1.25v12.72h-15.4v-1.71a16.54 16.54 0 0116.52-16.53zm.26 52.36h-.25a16.55 16.55 0 01-16.53-16.53V37.56h33.3v15.09a16.55 16.55 0 01-16.52 16.53z"></path>
	</svg>
</template>

<script>
export default {
	name: 'MouseIcon',
}
</script>
<style lang="scss">

$mouse--scroll-width: rem(80) !default;
$mouse--scroll-color: color(white) !default;

.scroll-link {
	overflow: visible;
}

.mouse {
	max-width: $mouse--scroll-width;
	width: 100%;
	height: auto;
	overflow: visible !important;

	> * {
		fill: $mouse--scroll-color;
	}

	.arrow {
		transition: transform 250ms ease;
	}
}

.wheel {
	animation-name: scrollWheel;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	will-change: transform, opacity;
	opacity: 1;
}

@keyframes scrollWheel {

	0%,
	20% {
		transform: translateY(0);
	}

	70% {
		opacity: 1;
	}

	100% {
		transform: translateY(10%);
		opacity: 0;
	}

}
</style>
