<template>
	<ul class="navigation-handle ms-4 d-flex align-items-center">
		<li class="navigation-handle--item--link login-portal">
			<b-dropdown
				size="lg"
				variant="link"
				offset="-60"
				toggle-class="text-decoration-none p-0 d-flex"
				no-caret
			>
				<template #button-content>
					<span
						class="text-left me-1 d-none d-xl-block font-weight-bold"
						>Anmelden</span
					>
					<font-awesome-icon :icon="['isd', 'login']" />
				</template>
				<b-dropdown-item
					href="https://isd-kundenportal.isd-service.de/login?redirectTo=%2Fcustomer"
					target="_blank"
					rel="noopener"
					class="login-portal-item"
					title="kundenportal"
				>
					{{ Customer }}
				</b-dropdown-item>
				<b-dropdown-item
					href="https://mitarbeiterportal.isd-service.de/fuer-mitarbeiter/mitarbeiterportal/"
					target="_blank"
					rel="noopener"
					class="login-portal-item"
					title="Mitarbeiterportal"
				>
					{{ Employee }}
				</b-dropdown-item>
			</b-dropdown>
		</li>
		<li>
			<nuxt-link
				exact
				to="/ueber-uns/kontakt-niederlassungen/kontaktformular.html"
				class="btn btn-pill btn-secondary d-flex ms-sm-2 ps-xl-4 pe-xl-4 mb-xl-1"
				title="Zum Kontaktformular"
			>
				<font-awesome-icon :icon="['isd', 'mail']" />
				<span class="d-none d-xl-block"> Kontakt </span>
			</nuxt-link>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'NavigationHandle',
	data() {
		return {
			Customer: 'Kundenportal',
			Employee: 'Mitarbeiterportal',
		}
	},
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
$navigation-handle--item--link--color: $primary !default;
$dropdown-menu--list-icon-color: color(primary, light) !default;
$dropdown--list-is-static-background-color: color(secondary, light) !default;

.login-portal {
	position: relative;
	margin-top: 0px;
	padding: 0.5rem 1.1rem;

	@include media-breakpoint-up(xl) {
		&:hover,
		&.show {
			background: $dropdown--list-is-static-background-color;
			color: $primary;
			border-radius: 5px;
		}
		.svg-inline--fa {
			margin-right: 0 !important;
		}
	}

	@include media-breakpoint-down(xl) {
		background: $secondary;
		width: 20px;
		height: 20px;
		padding: 10px;
		border-radius: 5rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-right: 1rem;

		svg {
			color: #fff;
			font-size: 16px;
		}

		&:hover {
			background: $primary;
		}
	}

	.btn-link:hover {
		color: $primary;
	}

	@include media-breakpoint-up(xl) {
		margin-right: 0;
	}
	.dropdown {
		.login-portal-item {
			padding: 0 1.2rem;
		}

		.dropdown-item {
			position: relative;

			&:focus {
				background-color: transparent;
				box-shadow: none;
			}

			font-size: rem(18);
			color: color(primary, dark);

			&:hover {
				color: $primary;
			}
			&:before {
				content: '';
				position: absolute;
				background: url('data:image/svg+xml,#{svg-arrow-circle-right($dropdown-menu--list-icon-color)}')
					center right no-repeat;
				background-size: contain;
				left: -5px;
				width: 15px;
				height: 15px;
				top: 7px;
				@include media-breakpoint-up(xl) {
					top: 11px;
				}
			}
		}
		.dropdown-toggle-no-caret:after {
			border-top: 0;
			border-right: 0;
		}
	}

	.dropdown-menu {
		top: 0.8rem !important;

		@include media-breakpoint-between(md, lg) {
			top: 4rem !important;
		}

		@include media-breakpoint-down(lg) {
			top: 1rem !important;
		}
	}
}

.navigation-handle .navigation-handle li a,
.navigation-handle--item--link {
	color: $navigation-handle--item--link--color;
	text-decoration: none;
}

.navigation-handle li a,
.navigation-handle--item--link {
	&:hover {
		color: $link-color;
	}
}

.navigation-handle .btn {
	.svg-inline--fa {
		margin-right: 10px;
		margin-top: 3px;
		vertical-align: middle;
	}
	&:hover:not(.btn-link) {
		color: $white;
	}

	@include media-breakpoint-down(xl) {
		width: 20px;
		height: 20px;
		text-align: center;
		flex-direction: column;
		align-items: center;
		padding: 10px;
		margin-top: 0;
		margin-bottom: 0;

		.svg-inline--fa {
			margin-right: 0;
		}
	}

	@include media-breakpoint-down(lg) {
		.fa-login {
			margin-top: 1px;
			font-size: 18px;
		}
	}
}

.navigation-handle--item--link:not(.login-portal) {
	padding: 0 10px 0;
	line-height: 0;
}
</style>
