<template>
	<div class="footer-main--links ps-lg-9 pe-lg-9">
		<div class="row">
			<div class="col-lg text-lg-left">
				ISD Immobilien Service Deutschland GmbH &amp; Co. KG
			</div>
			<div class="col-lg">
				<div class="social-icon-container">
					<a
						href="https://www.facebook.com/ISDGebaeudedienstleister/"
						target="_blank"
						rel="noopener"
						class="social-icon social-icon-facebook"
						title="facebook"
					>
						<font-awesome-icon
							:icon="['isd', 'facebook']"
						/>
					</a>
					<a
						href="https://www.xing.com/companies/immobilienservicedeutschland"
						target="_blank"
						rel="noopener"
						class="social-icon social-icon-xing"
						title="xing"
					>
						<font-awesome-icon
							:icon="['isd', 'xing']"
						/>
					</a>
					<a
						href="https://www.kununu.com/de/immobilien-service-deutschland"
						target="_blank"
						rel="noopener"
						class="social-icon social-icon-kununu"
						title="kununu"
					>
						<font-awesome-icon
							:icon="['isd', 'kununu']"
						/>
					</a>
					<a
						href="https://www.linkedin.com/company/immobilien-service-deutschland-gmbh-&-co-kg"
						target="_blank"
						rel="noopener"
						class="social-icon social-icon-linkedin"
						title="linkedin"
					>
						<font-awesome-icon
							:icon="['isd', 'linkedin']"
						/>
					</a>
				</div>
			</div>
			<div class="col-lg">
				<ul class="footer-menu">
					<li
						v-for="(linkFooter, key) in footerMenu"
						:key="key"
						:title="linkFooter.title"
					>
						<nav-link :to="linkFooter.link">
							{{linkFooter.title}}
						</nav-link>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'PageFooterLinks',
	computed: {
		...mapState({
			footerMenu: (state) => {
				return state.typo3.initial.footermenu || [];
			}
		})
	}
}
</script>

<style lang="scss">
	@import '~@/assets/scss/common';
	$footer-main--facebook-color: color(facebook-blue) !default;
	$footer-main--xing-color: color(xing-green) !default;
	$footer-main--kununu-color: color(kununu-black) !default;
	$footer-main--linkedin-color: color(linkedin-blue) !default;

	.footer-main--links .social-icon-container {

		display: inline-flex;
		align-content: center;
		align-items: center;
		@include media-breakpoint-down(lg) {
			margin: 20px 0;
		}


		.social-icon {
			margin: 0 10px;
			font-size: 32px;
		}

		.social-icon-facebook {
			color: $footer-main--facebook-color;
		}
		.social-icon-xing {
			color: $footer-main--xing-color;
		}
		.social-icon-kununu {
			color: $footer-main--kununu-color;
		}
		.social-icon-linkedin {
			color: $footer-main--linkedin-color;
		}
	}

	.footer-main--links ul.footer-menu {
		padding: 0;
		margin: 0 -10px;
		list-style: none;
		line-height: 20px;

		@include media-breakpoint-up(lg) {
			justify-content: flex-end;
			display: flex;
			justify-content: center;
		}

		li {
			padding: 0 10px;

			@include media-breakpoint-down(lg) {
				margin-bottom: 10px;
			}
		}
	}
</style>
