var render = function render(){var _vm=this,_c=_vm._self._c;return _c('client-only',[_c('ul',{staticClass:"navbar-nav"},[_vm._l((_vm.links),function(level1item,level1key){return _c('b-nav-item-dropdown',{key:level1key,ref:"dropdown",refInFor:true,class:{
				'is-active': level1item.active,
				'is-desktop': level1item.class,
				'has-icon': level1item.icon.iconName.length > 0
			},attrs:{"variant":"link","menu-class":"animate slideIn"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [(_vm.isIconPresent(level1item))?_c('span',{staticClass:"nav-link--icon"},[_c('font-awesome-icon',{attrs:{"icon":[
							level1item.icon.prefix,
							level1item.icon.iconName
						]}})],1):_vm._e(),_vm._v("\n\t\t\t\t"+_vm._s(level1item.title)+"\n\t\t\t")]},proxy:true}],null,true)},[_vm._v(" "),_c('media',{attrs:{"query":{minWidth: 992}}},[_c('b-row',[_c('b-col',[_c('b-tabs',{attrs:{"vertical":""}},_vm._l((level1item.children),function(level2item,level2key){return _c('b-tab',{key:level2key,class:{
										'is-active': level2item.active
									},attrs:{"title":level2item.title}},[_c('ul',{staticClass:"pt-4 ps-2 pe-2 dropdown--list-has-icons"},_vm._l((level2item.children),function(level3item,level3key){return _c('b-dropdown-item',{key:level3key,class:{
												'is-active': level3item.active,
												'has-icon': level3item.icon.iconName.length > 0
											},attrs:{"title":level3item.title}},[_c('nav-link',{attrs:{"to":level3item.link}},[(_vm.isIconPresent(level3item))?_c('span',{staticClass:"nav-link--icon"},[_c('font-awesome-icon',{attrs:{"icon":[
															level3item.icon.prefix,
															level3item.icon.iconName
														]}})],1):_vm._e(),_vm._v(" "),_c('span',{staticClass:"navlink--text"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(level3item.title)+"\n\t\t\t\t\t\t\t\t\t\t\t\t\t")])])],1)}),1)])}),1)],1),_vm._v(" "),_c('b-col',{attrs:{"sm":"4 dropdown--list-is-static"}},[_c('ul',{staticClass:"pt-4 ps-2 dropdown--list-has-icons"},_vm._l((level1item.metaMenu),function(metaMenuItem,metaMenukey){return _c('b-dropdown-item',{key:metaMenukey},[_c('nav-link',{attrs:{"to":metaMenuItem.link}},[(_vm.isIconPresent(metaMenuItem))?_c('span',{staticClass:"nav-link--icon"},[_c('font-awesome-icon',{attrs:{"icon":[
												metaMenuItem.icon.prefix,
												metaMenuItem.icon.iconName
											]}})],1):_vm._e(),_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(metaMenuItem.title)+"\n\t\t\t\t\t\t\t\t")])],1)}),1)])],1)],1),_vm._v(" "),_c('media',{attrs:{"query":{maxWidth: 991}}},[_c('ul',{staticClass:"mobile-menu"},[_c('div',{staticClass:"mobile-menu--header"},[(_vm.isIconPresent(level1item))?_c('span',{staticClass:"icon-block"},[_c('font-awesome-icon',{attrs:{"icon":[
									level1item.icon.prefix,
									level1item.icon.iconName
								]}}),_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(level1item.title)+"\n\t\t\t\t\t\t")],1):_vm._e(),_vm._v(" "),_c('a',{on:{"click":function($event){return _vm.closeAllDropdowns()}}},[_c('font-awesome-icon',{attrs:{"icon":[
									'fal', 'times'
								]}})],1)]),_vm._v(" "),_vm._l((level1item.children),function(level2item,level2key){return _c('li',{key:level2key,staticClass:"level-1--list",attrs:{"title":level2item.title}},[_c('a',{staticClass:"level-1--content",on:{"click":function($event){_vm.showIndex = level2item}}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(level2item.title)+"\n\t\t\t\t\t\t\t\t"),_c('font-awesome-icon',{attrs:{"icon":[
										'fal',
										'chevron-right'
									]}})],1),_vm._v(" "),_c('transition',{attrs:{"name":"slide"}},[(_vm.showIndex === level2item)?_c('ul',{staticClass:"dropdown--list-has-icons"},[_c('div',{staticClass:"mobile-menu--header"},[_c('span',{staticClass:"icon-block"},[_c('a',{on:{"click":function($event){_vm.showIndex = level1item}}},[_c('font-awesome-icon',{attrs:{"icon":[
											'fal',
											'chevron-left'
										]}}),_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(level2item.title)+"\n\t\t\t\t\t\t\t\t")],1)]),_vm._v(" "),_c('a',{on:{"click":function($event){return _vm.closeAllDropdowns()}}},[_c('font-awesome-icon',{attrs:{"icon":[
											'fal',
											'times'
										]}})],1)]),_vm._v(" "),_vm._l((level2item.children),function(level3item,level3key){return _c('b-dropdown-item',{key:level3key,staticClass:"level-2--list",attrs:{"title":level3item.title}},[_c('nav-link',{attrs:{"to":level3item.link}},[(_vm.isIconPresent(level3item))?_c('span',{staticClass:"nav-link--icon"},[_c('font-awesome-icon',{attrs:{"icon":[
												level3item.icon.prefix,
												level3item.icon.iconName
											]}})],1):_vm._e(),_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(level3item.title)+"\n\t\t\t\t\t\t\t\t")])],1)})],2):_vm._e()])],1)})],2)])],1)}),_vm._v(" "),_c('NavigationMainMobile')],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }