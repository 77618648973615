<template>
	<div v-if="content_animation">
		<div
			class="ce-textpic"
			:data-aos="content_animation"
			data-aos-anchor-placement="center-bottom"
			data-aos-duration="1000"
		>
			<media-gallery :gallery="gallery">
				<ce-header v-bind="$props" />
				<html-parser :content="bodytext" />
			</media-gallery>
		</div>
	</div>
	<div
		class="ce-textpic"
		v-else
	>
		<media-gallery :gallery="gallery">
			<ce-header v-bind="$props" />
			<html-parser :content="bodytext" />
		</media-gallery>
	</div>
</template>


<script>
	import CeTextpic from '~typo3/components/content/elements/CeTextpic.vue'
	export default {
		extends: CeTextpic,
		props: {
			content_animation: {
				type: String,
				required: false,
				default: ''
			}
		},
	}
</script>

<style lang="scss">
	@import '~@/assets/scss/common';
	$gridlayout--column-default-padding: 15px;
	$text-media--gallery--margin: $gridlayout--column-default-padding !default;

	$text-media--caption--background: color(white,base) !default;
	$text-media--caption--margin: 10px 0  !default;
	$text-media--caption--color: color(grey, dark) !default;
	$text-media--caption--font-size: rem(18) !default;
	$text-media--caption--line-height: 30px !default;
	$text-media--caption--font-weight: 300 !default;

	.ce-gallery {

	    &:not(&--vertical-intext) {
	        display: flex;
	        flex-direction: column;
	    }

	    &--horizontal--center {
	        align-items: flex-start;
	    }

	    &--horizontal-right {
			align-items: flex-start;
			.ce-gallery__col {
				margin: 5px 15px 20px 30px;

				@include media-breakpoint-down(md) {
					margin: 30px 0;
				}
			}
	    }

	    &--horizontal-left {
			align-items: flex-start;
			.ce-gallery__col {
				margin: 5px 30px 20px 15px;

				@include media-breakpoint-down(md) {
					margin: 30px 0;
				}
			}
	    }

	    &--vertical-above {
	        .ce-gallery__container {
	            margin-bottom: $text-media--gallery--margin;
			}
			.ce-gallery__col {
				@include media-breakpoint-down(md) {
					margin: 30px 0;
				}
			}
	    }

	    &--vertical-below {
	        .ce-gallery__container {
	            margin-top: $text-media--gallery--margin;
			}
			.ce-gallery__col {
				@include media-breakpoint-down(md) {
					margin: 30px 0;
				}
			}
	    }

	    .ce-gallery__container {

	        @include media-breakpoint-down(sm) {
				width: 100%;
	            img {
	                width: 100%;
	            }
		    }
	    }

	    &--vertical-intext:not(&--no-wrap) {
	        &::after {
	            content: "";
	            display: table;
	            clear: both;
	        }

	        &.ce-gallery--horizontal-right .ce-gallery__container {
	            float: right;
				margin-left: $text-media--gallery--margin;

				@include media-breakpoint-down(sm) {
					width: 100%;
				}

	        }

	        &.ce-gallery--horizontal-left .ce-gallery__container {
	            float: left;
				margin-right: $text-media--gallery--margin;

				@include media-breakpoint-down(sm) {
					width: 100%;
				}
	        }
	    }

	    &--vertical-intext.ce-gallery--no-wrap {
			display: flex;

			@include media-breakpoint-down(md) {
				flex-direction: column;
			}

	        &.ce-gallery--horizontal-right .ce-gallery__text {
	            order: -9999;
				margin-right: $text-media--gallery--margin;

				@include media-breakpoint-down(md) {
					margin: 30px 0;
				}
	        }

	        .ce-gallery__text {
	            flex: 1 1 0%;
	            align-items: center;

				@include media-breakpoint-down(md) {
					margin: 0;
				}
	        }
		}


	    &__row {

			display: flex;
			margin-left: -$text-media--gallery--margin;
			margin-right: -$text-media--gallery--margin;

			@include media-breakpoint-down(md) {
				flex-direction: column;
				margin: 0;
			}

	    }

	    &__col {
			width: 100%;
			margin: 0 $text-media--gallery--margin $text-media--gallery--margin;

			@include media-breakpoint-down(sm) {
				margin: $text-media--gallery--margin $text-media--gallery--margin;
			}
	    }

	}

	.ce-gallery figcaption {
	    background-color: $text-media--caption--background;
		margin: 0;
		padding: $text-media--caption--margin;

	    color: $text-media--caption--color;
	    font-size: $text-media--caption--font-size;
	    line-height: $text-media--caption--line-height;
	    font-weight: $text-media--caption--font-weight;
	}

</style>
