<template>
	<client-only>
		<ul class="navbar-nav">
			<b-nav-item-dropdown
				v-for="(level1item, level1key) in links"
				:key="level1key"
				v-bind:class="{
					'is-active': level1item.active,
					'is-desktop': level1item.class,
					'has-icon': level1item.icon.iconName.length > 0
				}"
				variant="link"
				menu-class="animate slideIn"
				ref="dropdown"
			>
				<template v-slot:button-content>
					<span
						class="nav-link--icon"
						v-if="isIconPresent(level1item)"
					>
						<font-awesome-icon
							:icon="[
								level1item.icon.prefix,
								level1item.icon.iconName
							]"
						/>
					</span>
					{{level1item.title}}
				</template>
				<media :query="{minWidth: 992}">
					<b-row>
						<b-col>
							<b-tabs
									vertical
								>
									<b-tab
										v-for="(level2item, level2key) in level1item.children"
										:title="level2item.title" :key="level2key"
										:class="{
											'is-active': level2item.active
										}"
									>
										<ul class="pt-4 ps-2 pe-2 dropdown--list-has-icons">
											<b-dropdown-item
												v-for="(level3item, level3key) in level2item.children"
												:title="level3item.title"
												:key="level3key"
												v-bind:class="{
													'is-active': level3item.active,
													'has-icon': level3item.icon.iconName.length > 0
												}"
											>
												<nav-link :to="level3item.link">
													<span
														class="nav-link--icon"
														v-if="isIconPresent(level3item)"
													>
														<font-awesome-icon
															:icon="[
																level3item.icon.prefix,
																level3item.icon.iconName
															]"
														/>
													</span>
														<span class="navlink--text">
															{{ level3item.title }}
														</span>
												</nav-link>
											</b-dropdown-item>
										</ul>
									</b-tab>
							</b-tabs>
						</b-col>
						<b-col sm="4 dropdown--list-is-static">
							<!-- static meta menu  -->
							<ul class="pt-4 ps-2 dropdown--list-has-icons">
								<b-dropdown-item
									v-for="(metaMenuItem, metaMenukey) in level1item.metaMenu"
									:key="metaMenukey"
								>
									<nav-link :to="metaMenuItem.link">
										<span
											class="nav-link--icon"
											v-if="isIconPresent(metaMenuItem)"
										>
											<font-awesome-icon
												:icon="[
													metaMenuItem.icon.prefix,
													metaMenuItem.icon.iconName
												]"
											/>
										</span>
										{{ metaMenuItem.title }}
									</nav-link>
								</b-dropdown-item>
							</ul>
						</b-col>
					</b-row>
				</media>
				<media :query="{maxWidth: 991}">
					<ul class="mobile-menu">
						<div class="mobile-menu--header">
							<span
								class="icon-block"
								v-if="isIconPresent(level1item)"
							>
								<font-awesome-icon
									:icon="[
										level1item.icon.prefix,
										level1item.icon.iconName
									]"
								/>
								{{level1item.title}}
							</span>
							<a
								@click="closeAllDropdowns()"
							>
								<font-awesome-icon
									:icon="[
										'fal', 'times'
									]"
								/>
							</a>
						</div>
						<li v-for="(level2item, level2key) in level1item.children"
							:title="level2item.title"
							:key="level2key"
							class="level-1--list"
						>
							<a
								class="level-1--content"
								v-on:click="showIndex = level2item"
							>
								{{ level2item.title }}
									<font-awesome-icon
										:icon="[
											'fal',
											'chevron-right'
										]"
									/>
							</a>
							<transition name="slide">
							<ul
								class="dropdown--list-has-icons"
								v-if="showIndex === level2item"
							>
								<div class="mobile-menu--header">
									<span class="icon-block">
									<a
										v-on:click="showIndex = level1item"
									>
										<font-awesome-icon
											:icon="[
												'fal',
												'chevron-left'
											]"
										/>
										{{level2item.title}}
									</a>
									</span>
									<a
										@click="closeAllDropdowns()"
									>
										<font-awesome-icon
											:icon="[
												'fal',
												'times'
											]"
										/>
									</a>
								</div>
								<b-dropdown-item
									v-for="(level3item, level3key) in level2item.children"
									:title="level3item.title"
									:key="level3key"
									class="level-2--list"
								>
									<nav-link :to="level3item.link">
										<span
											class="nav-link--icon"
											v-if="isIconPresent(level3item)"
										>
											<font-awesome-icon
												:icon="[
													level3item.icon.prefix,
													level3item.icon.iconName
												]"
											/>
										</span>
										{{ level3item.title }}
									</nav-link>
								</b-dropdown-item>
							</ul>
							</transition>
						</li>
					</ul>
				</media>
			</b-nav-item-dropdown>
				<NavigationMainMobile />
		</ul>
	</client-only>
</template>

<script>
import NavigationMainMobile from './NavigationMainMobile';
import { isIconPresentInObject } from '~/plugins/utilities/ObjectUtility';
import Media from 'vue-media';

export default {
	components: {
		Media,
		NavigationMainMobile
	},
	name: 'NavigationMain',
	props: {
		links: {
			type: Array
		}
	},
	data() {
		return {
			isHidden: true,
			showIndex: null,
			open: false,
			toggle: true
		}
	},
	methods: {
		isIconPresent (object) {
			return isIconPresentInObject(object) ;
		},
		closeDropdown (index) {
			this.$refs.dropdown[index].hide();
		},
		closeAllDropdowns () {
			this.$refs.dropdown.forEach((elem) => { elem.hide() });
		}
	}
}
</script>

<style lang="scss">
	@import '~@/assets/scss/common';
	$navbar-link--font-size: rem(20) !default;
	$navbar-link--font-weight: 600 !default;
	$navbar-link--margin: rem(10) !default;
	$navbar-link--arrow-color: $tertiary!default;

	$dropdown-menu--border: color(grey, light) !default;
	$dropdown-menu--icon-color: color(secondary, dark) !default;
	$dropdown-menu--list-color: color(primary, base) !default;
	$dropdown-menu--list-icon-color: color(primary, light) !default;
	$dropdown-menu--icon-size: 26px !default;
	$dropdown-menu--width: rem(950) !default;
	$dropdown-menu--height: 450px !default;

	$dropdown--list-is-static-background-color: color(secondary, light) !default;

	@include media-breakpoint-down(lg) {

		.navbar-nav {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0;
			background: color(white);
			min-height: 80px;
			display: flex;
			align-items: center;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-around;
			z-index: 10;
			// overflow: hidden;
		}

		.navbar-nav .nav-link {
			font-size: 12px;

			.nav-link--icon {
				display: flex;
				align-items: center;
				flex-direction: column;

				@include media-breakpoint-up(lg) {
					width: 60px;
    				display: block;
				}

				.svg-inline--fa {
					font-size: rem(28);
					margin-bottom: unit(2);
					color: $dropdown-menu--icon-color;
				}
			}

			&:after {
				display: none;
			}
		}

		.dropdown {
			a[aria-expanded="true"] {
				.nav-link--icon {
					.svg-inline--fa {
						color: color(secondary, base)
					}
				}
			}
		}

		.navbar-expand .navbar-nav .dropdown-menu {
			position: fixed !important;
			width: 100%;
			height: 100%;
			bottom: 0;
			z-index: -1;
			margin: 0;
			padding: 0;
			transform: none !important;
			box-shadow: none;
			overflow: hidden;
		}

		.navbar-expand .navbar-nav .nav-link {
			@include media-breakpoint-down(md) {
				padding: 0;
			}
		}

		.navbar-nav {
			&:before {
				content: "";
				position: absolute;
				z-index: 2;
				border: 0.5px solid $dropdown-menu--border;
				top: 0;
				width: 100%;
			}
		}
		//@TODO Change Trasition
		.slide-leave-active,
		.slide-enter-active {
			transition: 0.5s;
		}

		.slide-enter {
			transform: translate(100%, 0);
		}

		.slide-leave-to {
			transform: translate(100%, 0);
		}

		.mobile-menu {
			color: $dropdown-menu--list-color;

			.mobile-menu--header,
			.level-1--content {
				display: flex;
				align-items: center;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 15px 20px 15px 20px;
				font-weight: 800;

				.svg-inline--fa {
					font-size: $dropdown-menu--icon-size;
					color: color(secondary, dark);
					vertical-align: top;
				}
			}

			.level-2--list .svg-inline--fa {
				color: color(primary, light);
				font-size: $dropdown-menu--icon-size;
				width: 30px;
				margin-right: unit(4);
			}

			a {
				cursor: pointer;
			}
			.mobile-menu--header {
				border-bottom: 1px solid $dropdown-menu--border;
				margin-bottom: 10px;
				font-weight: 300;


				.icon-block {
					display: flex;
					align-items: center;

					.svg-inline--fa {
						margin-right: unit(4);
						font-size: 25px;
					}

					.fa-chevron-left {
						font-size: 20px;
					}
				}

				.svg-inline--fa {
					color: color(secondary, dark);
				}

			}

			.dropdown--list-has-icons {
				position: absolute;
				top: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				background: #fff;

				a {
					display: flex;
					align-items: center;
					cursor: pointer;
				}

				.mobile-menu--header {
					.fa-times {
						margin-right: 0;
					}
				}

				.level-2--list {

					padding: 15px 20px 15px 20px;

					a {
						color: color(primary, dark);
					}

				}

			}

			.level-1--content:active {
				.dropdown--list-has-icons {
					right: 0;
					display: block;
				}
			}

			.dropdown--list-login,
			.dropdown--list-search {

				background: color(secondary, light);
				padding: 10px 30px 10px 20px;
				border-bottom: 2px solid #fff;

				a {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					font-size: 12px;
					text-align: center;
				}

				.svg-inline--fa {
					font-size: 20px;
					margin-bottom: unit(1);
				}

				input {
					border: 0;
					background: transparent;
					box-shadow: none;
					padding: 0;
					font-size: 20px;
					opacity: 0.8;
				}
			}

		}

	}

	.nav-item .dropdown-item {
		margin: 0;
		padding: 0;
		white-space: normal;
		width: 100%;

		&:hover,
		&:focus {
			color: $dropdown-link-hover-color;
			background: none;
		}
	}

	@include media-breakpoint-up(lg) {
		.navbar-nav {
			margin-left: ($navbar-link--margin) *4;
			position: relative;
			background: none;
			border: none;
		}

		.navbar-nav .nav-link {

			font-weight: $navbar-link--font-weight;
			font-size: $navbar-link--font-size;
			margin: 0 $navbar-link--margin;
			color: $primary;

			&:hover,
			&.show {
				background:  $dropdown--list-is-static-background-color;
				color: $primary;
				border-radius: 5px;
			}

			.nav-link--icon {
				display: none;
			}

			& a:active, & a:focus {
				color: $dropdown-menu--list-color;
			}
		}

		.navbar-nav .nav-item {
			&.show {
				.dropdown-toggle {
					background:  $dropdown--list-is-static-background-color;
					color: $primary;
					border-radius: 5px;
					border: 0;
				}
			}

			&.is-desktop {
				display: none;
			}
		}

		.navbar-nav .nav-item {
			position: relative;

			a {
				&:after {
					content: "";
					background: url('data:image/svg+xml,#{svg-arrow-down($navbar-link--arrow-color)}') center right no-repeat;
					position: relative;
					width: 20px;
					height: 15px;
					padding-left: rem(25);
					border: none;
					vertical-align: middle;
					margin: 0;
				}
			}
		}

		.navbar-nav .dropdown-menu {
			width: $dropdown-menu--width;
			min-width: 100%;
			min-height: $dropdown-menu--height;
			top: 0.5rem !important;

			@include media-breakpoint-down(lg) {
				top: 4rem !important;
			}

			margin: 0;
			padding: 0;

			a {
				display: flex;
				align-items: center;
				text-decoration: none;

				&:after {
					display: none;
				}
			}

		}

		.navbar-nav .dropdown-menu .nav-tabs {
			border: 0;
		}

		.navbar-nav .dropdown-menu .nav-tabs .nav-link {

			width: rem(220);
			min-height: 160px;
			display: flex;
			align-items: center;
			padding: 0 20px 0 rem(40);
			margin: 0;
			border: 0;
		}

		.navbar-nav :nth-child(2) {
			.dropdown-menu .nav-tabs .nav-link {
				min-height: 185px;
			}
		}
		.navbar-nav :nth-child(3) {
			.dropdown-menu .nav-tabs .nav-link {
				min-height: 225px;
			}
			.navbar-nav .dropdown-menu {
				min-height: 375px;
			}
		}

		.navbar-nav .dropdown-menu .nav-tabs .nav-link.active {
			color: $secondary;
			border: none;

			&:after {
				content: '';
				@extend .triangle-right;
			}

		}

		.navbar-nav .dropdown-menu .nav-tabs .nav-link:hover,
		.nav-link.active {
			background: none;
			border: none;
			position: sticky;

			&:before {
				content: "";
				background: $dropdown--list-is-static-background-color;
				width: 80px;
				height: 80px;
				position: absolute;
				border-radius: 50rem;
				z-index: -1;
				margin-left: -1rem;
			}
		}

		.navbar-nav .dropdown-menu .nav-tabs .nav-link:after {
			background: none;
		}

		.navbar-nav .dropdown-menu .nav-tabs .nav-item {
			margin: 0;
			border-bottom: 1px solid $dropdown-menu--list-icon-color;
			border-right: 1px solid $dropdown-menu--list-icon-color;

			&:last-child {
				border-bottom: 0;
			}
		}

		.navbar-nav .dropdown-menu {
			ul {
				list-style: none;

				li {
					.svg-inline--fa {
						width: 30px;
						vertical-align: middle;
					}
				}
			}
		}

		.triangle-right {
			display: block;
			position: absolute;
			top: 40px;
			left: 100%;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 30px 0px 30px 20px;
			border-color: transparent transparent transparent #fff;
			filter: drop-shadow(1px 0 0 $dropdown-menu--list-icon-color) drop-shadow(0 .5px 0 $dropdown-menu--list-icon-color);
		}

		.navbar-nav .dropdown-menu .tab-pane ul.dropdown--list-has-icons {

			li {

				margin-bottom: 1rem;

				a {
					font-size: rem(18);
					color: color(primary, dark);
					width: 101%;

					&:hover {
						color: $primary;
					}

					&:focus {
						outline: none;
					}
				}

				.svg-inline--fa {
					font-size: rem(35);
					margin-right: rem(20);
					color: $dropdown-menu--list-icon-color;
				}

			}

		}

		.tab-content  {

			  a.dropdown-item {
					padding-left: rem(18);
					line-height: 21px;
					position: relative;
			  }

				a.dropdown-item:before {
					content: "";
					position: absolute;
					background: url('data:image/svg+xml,#{svg-circle($dropdown-menu--list-icon-color)}')
					center
					right no-repeat;
					left: -5px;
					width: 10px;
					height: 10px;
					top: 4px;

				}
				.has-icon {

					a.dropdown-item {

						padding-left: 0;

						&:before {
							display: none;
						}
					}

					.navlink--text {
						width: 100%;
					}
				}
		}

		.navbar-nav .dropdown-menu .dropdown--list-is-static {

			background: $dropdown--list-is-static-background-color;
			border-left: 2px solid rgba(92, 185, 229, 0.3);

			ul {
				font-weight: 600;

				li {

					line-height: rem(47);

					.svg-inline--fa {
						font-size: rem(28);
						margin-right: rem(20);
						color: $primary;
					}
				}
			}

		}
	}
</style>
